/**
 * @ComponentFor MagazineListPageViewModel
 */
import React from 'react';
import { styled } from '@glitz/react';
import { currentUrl, translate, replaceState, HistoryOptions } from '@avensia/scope';
import { EpiProperty } from '@avensia/scope-episerver';
import { Main, Appearance } from 'Shared/PageLayout';
import Image, { Ratio as ImageRatio, Preset as ImagePreset } from 'Shared/Image/Ratio';
import { bindInfiniteScroll } from 'Shared/infinite-scroll';
import { Part } from 'Shared/PageLayout';
import Button, { Variant as ButtonVariant, Appearance as ButtonAppearance } from 'Shared/Button';
import {
  pixelsToUnit,
  minTinyMediaQuery,
  minSmallMediaQuery,
  minMediumMediaQuery,
  minLargeMediaQuery,
  minHugeMediaQuery,
  sigma,
  epsilon,
  delison,
  delta,
  gamma,
  beta,
} from 'Shared/Style';
import Link from 'Shared/Link';
import MagazineListPageType from './MagazineListPageViewModel.type';

type PropType = MagazineListPageType;

export default class MagazineListPage extends React.Component<PropType> {
  currentlyReloading: boolean;
  unbindInfiniteScroll: () => void;
  ref = React.createRef<HTMLDivElement>();

  constructor(props: PropType) {
    super(props);
    this.currentlyReloading = false;
  }

  componentWillUnmount() {
    if (this.unbindInfiniteScroll) {
      this.unbindInfiniteScroll();
    }
  }

  hasMore() {
    return this.props.totalMagazineCount > this.props.magazineList.length;
  }

  reloadPage(count: number, skip: number, loadOptions?: HistoryOptions) {
    const url = currentUrl();
    url.searchParams.set('count', String(count));
    url.hiddenParams.set('skip', String(skip));
    return replaceState(url, loadOptions);
  }

  showMore = async () => {
    if (!this.currentlyReloading) {
      if (this.hasMore()) {
        this.currentlyReloading = true;
        const currentMagazineCount = this.props.magazineList.length;
        const newCount = currentMagazineCount + this.props.magazinesPerPage;
        const skip = currentMagazineCount / this.props.magazinesPerPage;
        try {
          await this.reloadPage(newCount, skip, {
            merge: (currentPage: MagazineListPageType, newMagazinePage: MagazineListPageType) => {
              return { ...currentPage, magazineList: newMagazinePage.magazineList };
            },
          });
          this.currentlyReloading = false;
        } catch (e) {
          this.currentlyReloading = false;
        }
      } else {
        if (this.unbindInfiniteScroll) {
          this.unbindInfiniteScroll();
        }
      }
    }
    this.unbindInfiniteScroll = bindInfiniteScroll(this.ref.current, () => this.showMore());
  };

  render() {
    const { magazineList = [] } = this.props;
    return (
      <Main appearance={Appearance.Narrow}>
        <EpiProperty for={this.props.page.mainContent} component={(props) => <Title {...props} />} />
        <Part elementRef={this.ref}>
          <Grid>
            {!!magazineList.length &&
              magazineList.map((item) => (
                <Col key={item.url}>
                  <MagazinePageLink to={item.url}>
                    <Teaser>
                      <Image
                        src={item.imageUrl.url}
                        alt={item.title}
                        ratio={ImageRatio.SixteenToNine}
                        preset={ImagePreset.Small}
                      />
                      <TeaserHeader>{item.title}</TeaserHeader>
                      <TeaserText>{item.preamble}</TeaserText>
                    </Teaser>
                  </MagazinePageLink>
                </Col>
              ))}
          </Grid>
        </Part>
        <Pagination css={this.hasMore() && { marginBottom: pixelsToUnit(317) }}>
          {this.hasMore() && (
            <ShowAll variant={ButtonVariant.Large} appearance={ButtonAppearance.Secondary} onClick={this.showMore}>
              {translate('/Magazine/ShowAll')}
            </ShowAll>
          )}
        </Pagination>
      </Main>
    );
  }
}

const Title = styled.div({
  margin: {
    top: pixelsToUnit(-25),
    bottom: pixelsToUnit(15),
    x: 'auto',
  },
  width: '100%',
});

const Grid = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
});

const Col = styled.div({
  flexBasis: '100%',
  maxWidth: pixelsToUnit(506),
  [minTinyMediaQuery]: {
    flexBasis: '50%',
  },
  [minSmallMediaQuery]: {
    flexBasis: '33.33%',
  },
});

const MagazinePageLink = styled(Link, {
  textDecoration: 'inherit',
  color: 'inherit',
  height: '100%',
  display: 'block',
});

const Teaser = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: {
    xy: pixelsToUnit(5),
  },
  [minMediumMediaQuery]: {
    padding: {
      xy: pixelsToUnit(12.5),
    },
  },
});

const TeaserHeader = styled.div({
  fontSize: epsilon,
  letterSpacing: pixelsToUnit(1.5),
  padding: {
    y: pixelsToUnit(5),
  },
  textTransform: 'uppercase',
  [minMediumMediaQuery]: {
    fontSize: delta,
  },
  [minLargeMediaQuery]: {
    fontSize: gamma,
    letterSpacing: pixelsToUnit(2),
  },
  [minHugeMediaQuery]: {
    fontSize: beta,
    lineHeight: 'normal',
    letterSpacing: pixelsToUnit(3),
    overflow: 'hidden',
  },
});

const TeaserText = styled.div({
  fontSize: sigma,
  lineHeight: pixelsToUnit(20),
  overflow: 'hidden',
  padding: {
    y: pixelsToUnit(5),
  },
  [minTinyMediaQuery]: {
    fontSize: sigma,
    lineHeight: pixelsToUnit(20),
  },
  [minMediumMediaQuery]: {
    fontSize: epsilon,
    lineHeight: pixelsToUnit(24),
  },
  [minHugeMediaQuery]: {
    fontSize: delison,
    lineHeight: pixelsToUnit(27),
  },
});

const Pagination = styled(Part, {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  margin: {
    y: pixelsToUnit(50),
    x: 0,
  },
  [minTinyMediaQuery]: {
    margin: {
      y: pixelsToUnit(100),
    },
  },
});

const ShowAll = styled(Button, {
  fontSize: epsilon,
  padding: {
    y: 0,
    x: pixelsToUnit(30),
  },
});
